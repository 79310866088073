@font-face {
  font-family: 'Red Hat display';
  src: url('./assets/font/RedHatDisplay-Regular.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-Medium.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-SemiBold.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-SemiBoldItalic.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-Bold.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-ExtraBold.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-BlackItalic.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-ExtraBoldItalic.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-MediumItalic.ttf') format('truetype'),
    url('./assets/font/RedHatDisplay-Black.ttf') format('truetype');
}
